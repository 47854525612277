import React from "react"
import Layout from "../../components/layout_under"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const Rezept = ({ data }) => {
    return (
        <Layout >
            TEst
            <h1>{data.mdx.frontmatter.title}</h1>
            <h3>Zutaten: {data.mdx.frontmatter.zutaten}</h3>
            <MDXRenderer>
                {data.mdx.body}
            </MDXRenderer>
        </Layout>
    )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        zutaten
      }
      body
    }
  }
`
export default Rezept